<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <p
        v-if="materialSistemaList.length > 1"
        class="title text-center"
      >
        Material {{ materialSistemaList.indexOf(routeParams.idmaterial_sistema) + 1 }} de {{ materialSistemaList.length }}
        <span
          v-if="material && material.subsis"
        >
          ({{ material.subsis.descripcion }})
        </span>
      </p>
      <v-form
        ref="form"
        lazy-validation
      >
        <material-sistema-form
          v-if="material && material.tsubfamilia"
          :id="routeParams.idmaterial_sistema"
          :meses-caducidad="material.tsubfamilia.meses_caducidad"
          @change-values="changeFormData"
        />
      </v-form>
      <b10-fab-button
        v-show="materialSistemaList.length > 0 && prevEnabled"
        left
        :icon="$vuetify.icons.values.prev"
        @click="clickSiguienteAnteriorMaterial(false)"
      />
      <b10-fab-button
        v-show="materialSistemaList.length > 0 && nextEnabled"
        :icon="$vuetify.icons.values.next"
        @click="clickSiguienteAnteriorMaterial(true)"
      />
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import Data from './MaterialSistemaEditData'
import MaterialSistemaForm from '../components/MaterialSistemaForm'
import { toDate } from '@/utils/date'
import { encodeExtraQuery } from '@/utils/router'

export default {
  components: {
    MaterialSistemaForm
  },
  mixins: [formPageMixin],
  data () {
    return {
      material: {},
      materialSistemaList: [],
    }
  },
  computed: {
    prevEnabled () {
      const actual = this.materialSistemaList.indexOf(this.routeParams.idmaterial_sistema)
      return actual > 0
    },
    nextEnabled () {
      const actual = this.materialSistemaList.indexOf(this.routeParams.idmaterial_sistema)
      return actual < this.materialSistemaList.length - 1
    },
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    // routeQuery idparte_trabajo_matsist: para el refresh dirty cuando vienes de lista de material afectado
    async loadPage () {
      if (this.routeQuery.extra && this.routeQuery.extra.ids) {
        this.materialSistemaList = this.routeQuery.extra.ids
      }
      this.material = await Data.selectMaterialSistema(this, this.routeParams.idmaterial_sistema)
      this.title = `Modificar - ${this.material.material_sistema.descripcion}`
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async _submitForm () {
      // caso especial de campos fecha -> convertir a Date si se modificaron
      if (this.formData.fsistema && typeof(this.formData.fsistema) === 'string') {
        this.formData.fsistema = toDate(this.formData.fsistema)
      }
      // fultrevision (no debe ser editable)
      // fprox_revision (no existe aún en offline, es un campo calculado en el servidor)
      if (this.formData.fult_retimbrado && typeof(this.formData.fult_retimbrado) === 'string') {
        this.formData.fult_retimbrado = toDate(this.formData.fult_retimbrado)
      }
      // fprox_retimbrado (no existe aún en offline, es un campo calculado en el servidor)
      if (this.formData.fcaducidad && typeof(this.formData.fcaducidad) === 'string') {
        this.formData.fcaducidad = toDate(this.formData.fcaducidad)
      }
      if (this.formData.ffin_garantia && typeof(this.formData.ffin_garantia) === 'string') {
        this.formData.ffin_garantia = toDate(this.formData.ffin_garantia)
      }
      if (this.formData.ffabricacion && typeof(this.formData.ffabricacion) === 'string') {
        this.formData.ffabricacion = toDate(this.formData.ffabricacion)
      }
      await Data.update(this, this.routeParams.idmaterial_sistema, this.formData, this.routeQuery.idparte_trabajo)
    },
    async submitForm () {
      await this._submitForm()
      await this.$dirty.modified(this.$dirty.ENTITIES.local.parteTrabajoMatsist, this.routeQuery.idparte_trabajo_matsist)
      await this.$dirty.modified(this.$dirty.ENTITIES.local.parteTrabajoMatsistSubsis, this.material.subsis.idsubsis)
      await this.$dirty.modified(this.$dirty.ENTITIES.local.materialSistema, this.routeParams.idmaterial_sistema)
      this.$appRouter.go(-1)
    },
    async clickSiguienteAnteriorMaterial (siguiente) {
      const actual = this.materialSistemaList.indexOf(this.routeParams.idmaterial_sistema)
      let idmaterialSistema = null
      if (siguiente) {
        if (actual < this.materialSistemaList.length - 1) {
          idmaterialSistema = this.materialSistemaList[actual + 1]
        }
      } else {
        if (actual > 0) {
          idmaterialSistema = this.materialSistemaList[actual - 1]
        }
      }
      if (idmaterialSistema !== null) {
        await this._submitForm()
        this.$appRouter.replace({
          name: 'offline__material-sistema-edit',
          params: {
            idmaterial_sistema: idmaterialSistema,
          },
          query: {
            extra: encodeExtraQuery(this.routeQuery.extra),
          },
        })
      }
    },
  },
}
</script>
