export default {
  selectFabricantes (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.fabricante.select({
      where: tables.fabricante.estado.gt(0)
    })
  },
  selectAgentesExtintores (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.agenteExtintor.select({
      where: tables.agente_extintor.estado.gt(0)
    })
  },
  selectAgentesPropelentes (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.agentePropelente.select({
      where: tables.agente_propelente.estado.gt(0)
    })
  },
  async selectTsubsis (Vue, idmaterialSistema) {
    const tables = Vue.$offline.db.tables
    const tsubsis = await Vue.$offline.db
      .select()
      .from(tables.material_sistema)
      .innerJoin(
        tables.subsis,
        tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
      )
      .innerJoin(
        tables.tsubsis,
        tables.subsis.idtsubsis.eq(tables.tsubsis.idtsubsis)
      )
      .where(tables.material_sistema.idmaterial_sistema.eq(idmaterialSistema))
      .exec()
    return tsubsis[0].tsubsis
  },
  selectTDatoArticuloValor (Vue, idtdatoArticulo) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.tdatoArticuloValor.select({
      where: tables.tdato_articulo_valor.idtdato_articulo.eq(idtdatoArticulo)
    })
  },
  selectTDatoArticulo (Vue, idfichaTecnica) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.tdatoArticulo.select({
      where: Vue.$offline.db.op.and(
        tables.tdato_articulo.estado.gt(0),
        tables.tdato_articulo.para_material_sis.eq(true),
        tables.tdato_articulo.idficha_tecnica.eq(idfichaTecnica)
      )
    })
  },
  selectDatoMaterialSistemaValor (Vue, idmaterialSistema, idtdatoArticulo) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.datoMaterialSistema.select({
      where: Vue.$offline.db.op.and(
        tables.dato_material_sistema.idmaterial_sistema.eq(idmaterialSistema),
        tables.dato_material_sistema.idtdato_articulo.eq(idtdatoArticulo)
      )
    })
  },
  selectTipoImportanciaMaterial(Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.timportanciaMaterial.select({
      where: Vue.$offline.db.op.and(
        tables.timportancia_material.estado.gt(0)
      )
    })
  },
  async selectFormData (Vue, id) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.material_sistema)
      .innerJoin(
        tables.articulo,
        tables.material_sistema.idarticulo.eq(tables.articulo.idarticulo)
      )
      .where(tables.material_sistema.idmaterial_sistema.eq(id))
      .exec()
    )[0]
  },
}
