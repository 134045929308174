import { v4 as uuidv4 } from 'uuid'
import { toDate } from '@/utils/date'

export default {
  async selectMaterialSistema (Vue, idmaterialSistema) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.material_sistema)
      .innerJoin(
        tables.articulo,
        tables.material_sistema.idarticulo.eq(tables.articulo.idarticulo)
      )
      .innerJoin(
        tables.subfamilia,
        tables.articulo.idsubfamilia.eq(tables.subfamilia.idsubfamilia)
      )
      .innerJoin(
        tables.tsubfamilia,
        tables.subfamilia.idtsubfamilia.eq(tables.tsubfamilia.idtsubfamilia)
      )
      .innerJoin(
        tables.subsis,
        tables.material_sistema.idsubsis.eq(tables.subsis.idsubsis)
      )
      .where(tables.material_sistema.idmaterial_sistema.eq(idmaterialSistema))
      .exec()
    )[0]
  },
  async update (Vue, id, formData, idparteTrabajo) {
    await Vue.$offline.materialSistema.updateSync({
      idmaterial_sistema: id,
      descripcion: formData.descripcion,
      unidades: formData.unidades,
      fsistema: formData.fsistema,
      nserie: formData.nserie,
      nelemento: formData.nelemento,
      ubicacion: formData.ubicacion,
      netiqueta: formData.netiqueta,
      num_zona: formData.num_zona,
      evento: formData.evento,
      funcion: formData.funcion,
      particion: formData.particion,
      // fultrevision (no debe ser editable)
      // fprox_revision (no existe aún en offline, es un campo calculado en el servidor)
      fult_retimbrado: formData.fult_retimbrado,
      // fprox_retimbrado (no existe aún en offline, es un campo calculado en el servidor)
      fcaducidad: formData.fcaducidad,
      ffin_garantia: formData.ffin_garantia,
      idfabricante: formData.idfabricante,
      ffabricacion: formData.ffabricacion ? toDate(formData.ffabricacion) : null,
      idagente_extintor: formData.idagente_extintor,
      idagente_propelente: formData.idagente_propelente,
      volumen: formData.volumen,
      peso_total: formData.peso_total,
      peso_agente_extintor: formData.peso_agente_extintor,
      eficacia: formData.eficacia,
      presion: formData.presion,
      idtimportancia_material: formData.idtimportancia_material,
      observaciones: formData.observaciones,
      observaciones_internas: formData.observaciones_internas,
    })
    for (let tdato of formData.datosArticulo) {
      if (tdato.valor) {
        if (tdato.iddato_material_sistema) {
          await Vue.$offline.datoMaterialSistema.updateSync({
            iddato_material_sistema: tdato.iddato_material_sistema,
            valor: tdato.valor
          })
        } else {
          await Vue.$offline.datoMaterialSistema.insertSync({
            iddato_material_sistema: uuidv4(),
            idmaterial_sistema: id,
            idtdato_articulo: tdato.idtdato_articulo,
            valor: tdato.valor,
            estado: 1,
          })
        }
      } else {
        if (tdato.iddato_material_sistema) {
          await Vue.$offline.datoMaterialSistema.deleteSync(tdato.iddato_material_sistema)
        }
      }
    }
    // Si se está editando desde un parte, actualizo los datos de orden_trabajo_matsist
    if (idparteTrabajo) {
      const parteTrabajo = await Vue.$offline.parteTrabajo.row(idparteTrabajo)
      const ordenTrabajoMatsist = (await Vue.$offline.ordenTrabajoMatsist.selectPorIdmaterialSistema(parteTrabajo.parte_trabajo.idorden_trabajo, id))[0]
      await Vue.$offline.ordenTrabajoMatsist.updateSync({
        idorden_trabajo_matsist: ordenTrabajoMatsist.orden_trabajo_matsist.idorden_trabajo_matsist,
        // fultrevision (no debe ser editable)
        // fprox_revision (no existe aún en offline, es un campo calculado en el servidor)
        fult_retimbrado: formData.fult_retimbrado,
        // fprox_retimbrado (no existe aún en offline, es un campo calculado en el servidor)
      })
    }
  }
}
